@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700|Poppins:400,700&display=swap");
@import "style-variables.scss";
/*@import "./app/common/variables.scss";*/

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

body {
    font-family: $font-family-sans-serif;
    background-color: $body-bg;
}

h1, h2, h3, h4, h5 {
    font-weight: 400;
    font-family: 'Poppins';
}

.topnavbar {
    background-color: $nav-top-bg;
    transition: all 0.5s;
    background: linear-gradient(to left, $nav-top-bg-start, $nav-top-bg-end, $nav-top-bg-start);
    
    .other-database & {
        background: linear-gradient(to left, $nav-top-bg-start, #17bda0, $nav-top-bg-start);
    }

    .navbar-header {
        width: 140px;
        padding-left: 5em;
    }
}


.topnavbar .navbar-nav > .nav-item {
    .sidebar-toggle {
        font-size: 150%;
        padding-top: 0.7em;
    }

    > .nav-link {
        padding: 0.5rem .95rem;
        font-size: 2em;
        
        &:hover, &:focus {
            color: #fea30c;
        }
    }

    &.show > .nav-link {
        &:hover, &:focus {
            color: #fea30c;
        }
    }
}

.clickable {
    cursor: pointer;
}

.swal2-title {
    font-weight: 400 !important;
}

.badge {
    font-weight: 300;
}

.heading-sub-content {
    background: #fff;
    margin: -20px;
    padding: 10px 20px;
    margin-bottom: 20px;
}

.breadcrumb {
    background: none;
    margin-top: -15px;
    padding: 0;
}

.swal2-icon {
    font-family: "Source Sans Pro", sans-serif !important;
}

#nprogress {
    .bar {
        background: #17bda0;
        height: 5px;
    }

    .peg {
        box-shadow: 0 0 10px #17bda0, 0 0 5px #17bda0;
    }
}

.spinner-grow-xl {
    width: 4em;
    height: 4em;
}

.animated {
    animation-fill-mode: none !important;
}

.form-control.label-edit {
    padding: 0.375rem 0.375rem;

    &:not(:focus) {
        border-color: transparent;

        &:hover {
            border-color: $gray-400;
        }
    }

    &:focus {
        box-shadow: inset 0 0px 2px $input-focus-border-color !important;
    }
}

.switch.is-invalid ~ .invalid-feedback {
    display: block;
}

.custom-multiselect {
    .multiselect__tags {
        font-size: 12px;
    }
}

.dropdown-item {
    .custom-control-label {
        width: 100%;
    }
}

/*.aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item > span, .aside-collapsed-text .sidebar > .sidebar-nav > li > a > span {
    font-size: 0.7em;
}

.aside-collapsed-text .sidebar > .sidebar-nav > li > a > em {
    font-size: 1.1em;
}*/

.content-heading {
    .alert {
        font-size: 0.5em;
    }
}

.brand-logo {
    &>img {
        max-height: 35px;
    }
}

.modal {
    .modal-dialog.modal-fullscreen {
    max-height: calc(100vh - 143px);
    width: 100%;
    max-width: calc(100% - 2em);
    height: 100%;
      .modal-content {
          height: 100%;
        .modal-body {
            height: 100%;
          overflow-y: auto;
        }
      }
    }
  }

// .modal-fullscreen {
//     width: 100%;
//     max-width: calc(100% - 2em);
//     .modal-body {
//         max-height: calc(100vh - 143px);
//         overflow-y: auto;
//     }
// }

.custom-select {
    font-size: 1em;
}

.content-wrapper {
    transition: padding 0.2s;
}

.offsidebar-open.with-padding .content-wrapper {
    padding-right: 255px;
    @include media-breakpoint-down(lg) { 
        padding-right: 20px;
    }
}

.offsidebar {
    padding: 10px;
    z-index: 2;
    @include media-breakpoint-down(lg) { 
        display:none;
     }
}

.sidebar-nav {
    font-size: 13px;

    >li>a {
        display: flex;
        align-items: center;
        >span.icon {
            width: 1.8em;
            font-size: 1.25em;
            margin-right: 0;
        }
    }
}

.sidebar {
    .other-database &:after {
        box-shadow: -1px 0px 3px 1px rgb(23, 189, 160);
        background: none;
    }
}

.custom-switch.b-custom-control-lg, .custom-checkbox.b-custom-control-lg {
    font-size: 1em;
    .custom-control-label {
        line-height: 2.2;
    }
}

.material-design-icon {
    display: inline-flex;
    align-self: center;
    position: relative;
    height: 1em;
    width: 1em;
    > .material-design-icon__svg {
        height: 1em;
        width: 1em;
        fill: currentColor;
      }  
  }


.card.with-image {
    .card-body {
        display: flex;
        flex-direction: column;

        .image {
            max-width: 50%;
            align-self: center;
        }
        .text {
            margin-top: 1em;
            flex-grow: 1;
            text-align: center;
        }
    }
}

%highlights {
    transition: background-color 0.2s;
    color: #fff;
}

.highlight {
    &-purple {
        @extend %highlights;
        background-color: #7266ba;
        &:hover {
            background-color: #564aa3;
        }
    }

    &-primary {
        @extend %highlights;
        background-color: #5d9cec;
        &:hover {
            background-color: #2f80e7;
        }
    }
}

.content-wrapper .content-heading h2 {
    color: #555;
    margin-bottom: 0;
    padding-bottom: 0;
}

.aside-collapsed .sidebar > .sidebar-nav > li > a > span.icon {
    font-size: 2.6em;
    display: block !important;
    width: 100%;
}

.square-toggle {
    display: inline-flex;
    height: 105px;
    width: 105px;
    border: 1px solid $border-color;
    align-items:center;
    justify-content:center;
    cursor: pointer;
    user-select: none;
    transition: $transition-base;
    box-shadow: #999 2px 2px 3px 0px;
    margin: map-get($map: $spacers, $key: 2);

    &:hover, &.active {
        color: #fff;
    }

    &:hover {
        background: $green-light;
        transform: translateY(-3px);
    }

    &.active {
        background: $green;
        box-shadow: #999 2px 2px 3px 0px;
        transform: translateY(-6px);
    }
}

#HW_badge_cont {
    &.HW_visible {
        align-self: center;
    }
}

.list-group-item + .list-group-item {
    border-top-width: $list-group-border-width;
}

.features {
    display: flex;
    .features-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        width: 26px;
        text-align: center;
        height: 26px;
        border-radius: 50%;
        background: #fff;
        color: #2255d0;
        line-height: 26px;
        margin-top: -2px;
        margin-right: 1em;
    }
}

#root, .layout-fixed {
    height: 100%;
}

.no-caret.dropdown-toggle:after {
    content:none;
}

.marking-bubble {
    width: 30px; height: 30px;
    transition: all 0.2s;
    background: #fff;
}

.marking-bubble svg {
    font-size:1.2em;
}

.marking-bubble.active {
    transform: translateY(-5px);
    background: rgb(93, 156, 236);
    color: #fff;
}

.custom-checkbox.custom-checkbox-lg {
    .custom-control-label {
        line-height: 2.2em;
        &:after, &:before {
            width: 1.5em;
            height: 1.5em;
        }
    }
}

.sidebar-nav>li>a.active {
    background-color: #fcfcfc;
    color: #23b7e5
}

svg.MuiSvgIcon-root {
    fill: #333;

    .text-white &, &.text-white { 
        fill: #fff;
    }

}


.react-datetime-picker__wrapper {
    border: 0 !important;
}

.faded {
    opacity: 0.6;
    &.more {
        opacity: 0.4;
    }
    &.less {
        opacity: 0.8;
    }
}

.notification-type-colour {

    &::before {
        content: " ";
        white-space: pre;
    }

    width: 10px;
    height: 100%;
}

.page-item.active .page-link {
    pointer-events: none;
    z-index: 1;
    color: #fff;
    background-color: #5d9cec;
    border-color: #5d9cec;
}

.tooltip-inner {
    max-width: none;
}
.react-datepicker-popper {
    z-index: 9999 !important;
}

.commentDiv{
    max-width: 400px;
    padding: 7px 15px;
    white-space: normal;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    background: #3481c0;
}
.visual-options{
    background: #f5fafb;
}

.visual-filter .add-filter{
    cursor: pointer;
    padding: 0.375rem 1rem !important;
    border-radius: 3px;
    text-transform: capitalize;
    background: #3793d5;
    color:#fff !important;
    font-weight: normal !important;
}
.visual-filter .visual-value input[type=submit] { 
    display: none !important;
  }

.visual-filter .chip{
    background-color: #3896d8;
    color: #fff;
    margin-right: 5px;
    padding: 7px;
    cursor: pointer;
    float: left;
    border-radius: 4px;
}
.visual-filter .visual-options{
    padding: 10px !important;
    text-transform: capitalize;
}
.visual-filter input[type=submit]{
    text-transform: uppercase;
    color: #fff;
    background: #3895d8;
}

.visual-filter .visual-value {
    height: 20px;
    margin-top: 32px;
    position: absolute !important;
}

.visual-filter input[type=submit] {
    padding: 5px 10px;
    border-radius: 0.1px;
    position: absolute !important;
}

.search-with{    position: absolute;right: 0;}
.search-with .visual-filter{position: static;margin-right:15px;}
.search-with{    display: flex;justify-content: flex-end;}
.search-with .visual-filter .visual-value{    margin-top: 38px;}




// -------------------- Custom_Forms_Property_________________
.custom_form_header{
    line-height: 1.1;
    color: #929292;
    padding: 15px;
    font-weight: normal;
    background-color: #fafbfc;
    border-bottom: 1px solid #cfdbe2;
    
}
.custom_form_header h4 , .custom_form h4{
    color: #555;
    font-weight: 400;
    font-family: 'Poppins';
}
.custom_form{
    line-height: 1.1;
    color: #929292;
    padding: 15px;
    font-weight: normal;
    background-color: #fafbfc;
    border-bottom: 1px solid #cfdbe2; 
    margin-top:20;
}
.cust_form .txt_cust_form{
  margin-top:3px;
  margin-bottom: 3px;
}
.ddl-para,
.ddl-para-icon{
  margin-top:8px;
}
.ddl-para{
    padding-left: 5px;
    padding-top: 2px;
}
.para-icon{
    padding:2px;
}
.icon-ten-margin{
  margin-top:10px;
  cursor: pointer;
}
.icon-delete{
    color: gray;
    margin-right: 10px;
}
.form-td{
  border:0px !important;
  background-color:transparent !important;
  padding-left:0px !important
}
// .chk-div{
//   display: flex !important;
// }
.chk-div .form-group {
    margin:10px !important;
    margin-top:0px !important;
    display: inline-block;
}
.form-mandatory {
    display: flex;
    margin-right: 5px;
}
.form-mandatory .MuiSwitch-input{
    color: #4b515d !important;
  border: 1px solid #B8B6B6 !important;
  box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2) ;
}
.form-mandatory .form-label{
    margin: 12px;
    margin-bottom: 25px;
    margin-left: 0px;
}
.btn-add{
    display:flex;
    padding-top: 7px;
}
.five_margin{margin:5px;}
.ddl-label{
    padding-left: 5px;
    padding: 5px;
}
.topPadding-five{
    padding-top:5px;
}
.validate-para{
    padding-left: 5px;
    color: red;
}

.flex-display{
    display: flex
}
.flex-display .MuiSvgIcon-root{
    margin:5px;
}
.text-right{float: right;}
.valid-text{color: red;}
.btn-add-float{float: right;}
.btn-submit,
.custom_form-margin{
    margin-top:30px;
}
///----------------- request edit form property -----------------
.readonly-form:read-only {
    cursor: not-allowed !important;
  }
.readonly-form{
    pointer-events: none !important;
}

.readonly-form .react-datetime-picker__clear-button__icon,
.readonly-form .css-6q0nyr-Svg,
.readonly-form .css-12jo7m5,
.readonly-form .form-control,
.readonly-form .css-1uccc91-singleValue,
.readonly-form .form-label,
.readonly-form .flex-grow-1,
.readonly-form .react-datetime-picker__inputGroup__leadingZero,
.readonly-form input[type="textarea"],
.readonly-form input[type="number"]{
    color: #999 !important;  
}

//---------------------- Answer Page Property Defeine Here ----------------------
.answer_page_header{
 border-top: 9px solid #82b1efb8;
}
.answer_page_header .answer_header_div{
    padding:5px;
}
.answer_page_header .answer_header_div_left{
    text-align: end;
    padding-left: 10px;
}
.pd-20{
    padding:20px;
}
.pt-20{
    padding-top:20px;
}
.answer_page_header .btn-submit{
    text-align: center;
}
.answer_page_header .btn-submit-sec{
    text-align: end ;
}
.answer_page_header .question_name,
.answer_page_header h3 b{
    color: black !important;
}
.ans-chk-div .form-check-label{
    padding-top:4px;
}
.ml-20{margin-left: 20px}

///----------------- request edit form property -----------------
.readonly-form:read-only {
    cursor: not-allowed !important;
  }
.readonly-form{
    pointer-events: none !important;
}

.readonly-form .react-datetime-picker__clear-button__icon,
.readonly-form .css-6q0nyr-Svg,
.readonly-form .css-12jo7m5,
.readonly-form .form-control,
.readonly-form .css-1uccc91-singleValue,
.readonly-form .form-label,
.readonly-form .flex-grow-1,
.readonly-form .react-datetime-picker__inputGroup__leadingZero,
.readonly-form input[type="textarea"],
.readonly-form input[type="number"]{
    color: #999 !important;  
}



.no-record-div{
    text-align: center;
    padding: 50px;
}
.no-record-div p{
    font-size:25px;
}
.value-para{
    padding:5px;
}

.btn-resend{
   pointer-events: auto !important;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid blue;
    border-bottom: 16px solid blue;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .non-editable-div{
    padding: 5px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    color: #a1a1a1;
   // height: 75px;
  }
 
  .btn-add-question{
      display: contents !important;
  }
  .btn-add-question .btn-add-sec{
    margin-top: 30px;
    margin-right: 20px;
  }
  .btn-add-question a {
    padding:15px;
  }
  .btn-add-question a svg{
      margin-top:15px;
  }
  .padding-left-10{
      padding-left:10px;
  }


//   ------------- reports page css functionality defeine

.header-div-reports{
    border-top: 9px solid rgba(130,177,239,.7215686274509804);
    margin: 30px;
    border-radius: 5px;
    border-bottom: 1px solid #cfdbe2;
    border-left: 1px solid #cfdbe2;
    border-right: 1px solid #cfdbe2;
}
.header-div-reports-first{
    margin: 30px;
    border-radius: 5px;
    border: 1px solid #cfdbe2;
}
.contact_div{
    float: right;
}
.contact_div-footer{
    float: right;
    margin:-15px;
}
.file-para{
    margin-top:5px;
}

.pt-50{
    padding-top:50px;
}
.pt-30{
    padding-top: 30px;
}
.pt-80{
    padding-top: 80px;
}
.pt-50{
    padding-top:50px;
}
.pb-30{
    padding-bottom: 30px;
}

@media(max-width:691px) {
    .pt-50,
    .pt-80,
    .pt-30{
        padding-top:5px;
    }

    .btn-Download{
        margin-bottom: 20px;
    }
}
.btn-Download{
    width: 85%;
    padding:16px;
}
